import { useState } from "react";
import { DateTimeInput, DateTimeInputProps, TextInput, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";

export const TextWithCharCountInput = ({ source, maxLength, ...props }: any) => {
  const record = useRecordContext();
  const [charCount, setCharCount] = useState(record ? record[source]?.length : 0);

  const handleTextInputChange = (event: any) => {
    setCharCount(event.target.value.length);
  };

  return (
    <div>
      <TextInput source={source} onChange={handleTextInputChange} defaultValue={record ? record[source] : ""} {...(maxLength && { maxLength })} {...props} />
      <div>
        <div>{maxLength ? `${charCount} / ${maxLength}` : charCount}</div>
      </div>
    </div>
  );
};

export const DateTimeWithSecInput = (props: any) => <DateTimeInput {...props} inputProps={{ step: 1 }} format={formatDateTime} />;

type ButtonProps = {
  calcSetDateTimeSec: () => Date;
  buttonText: string;
};

type DateTimeSecWithButtonInputProps = DateTimeInputProps & ButtonProps;

export const DateTimeSecWithButtonInput = ({ source, buttonText, calcSetDateTimeSec, ...rest }: DateTimeSecWithButtonInputProps) => {
  const { setValue } = useFormContext();

  const onClick = () => {
    const value = calcSetDateTimeSec();
    setValue(source, value);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
      <DateTimeWithSecInput source={source} rest={rest} />
      <Button variant="outlined" onClick={onClick}>
        {buttonText}
      </Button>
    </Box>
  );
};

const leftPad =
  (nb = 2) =>
  (value: any) =>
    ("0".repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return "";
  const yyyy = leftPad4(value.getFullYear());
  const MM = leftPad2(value.getMonth() + 1);
  const dd = leftPad2(value.getDate());
  const hh = leftPad2(value.getHours());
  const mm = leftPad2(value.getMinutes());
  const ss = leftPad2(value.getSeconds());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}`;
};

const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

const formatDateTime = (value: string | Date) => {
  if (value == null || value === "") {
    return "";
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  if (dateTimeRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};
