export const Category = {
  Event: "event",
} as const;

export type Category = (typeof Category)[keyof typeof Category];

export class AnnouncementBuilder {
  constructor() {}

  public static build(
    title: string,
    body: string,
    category: Category,
    tag: string,
    date: Date
  ) {
    const childWindow = (
      document.getElementById("preview-html") as HTMLIFrameElement
    ).contentWindow;
    if (!childWindow) return;

    const child = childWindow.document;

    let headerElement = child.getElementById("header");
    let titleElement = child.getElementById("title");
    let bodyElement = child.getElementById("body");

    if (!headerElement || !titleElement || !bodyElement) {
      return;
    }

    const categoryChild = this.buildCategoryElement(category, tag);
    const dateChild = this.buildDateElement(date);
    headerElement = this.removeAllChild(headerElement);
    headerElement.appendChild(categoryChild);
    headerElement.appendChild(dateChild);

    const titleChild = this.buildTitleElement(title);
    titleElement.replaceWith(titleChild);

    const bodyChild = this.buildBodyElement(body);
    bodyElement.replaceWith(bodyChild);

    return;
  }

  public static buildCategoryElement(
    category: string,
    tag: string
  ): HTMLParagraphElement {
    const element = document.createElement("p");
    element.className = `category ${category}`;
    element.textContent = tag;

    return element;
  }

  public static buildDateElement(date: Date): HTMLParagraphElement {
    const element = document.createElement("p");
    element.className = "date";
    element.textContent = date.toLocaleString();

    return element;
  }

  public static buildTitleElement(title: string): HTMLParagraphElement {
    const parent = document.createElement("div");
    parent.id = "title";
    parent.className = "block_title";

    const element = document.createElement("p");
    element.textContent = title;

    parent.appendChild(element);

    return parent;
  }

  public static buildBodyElement(body: string): HTMLParagraphElement {
    const element = document.createElement("div");
    element.id = "body";
    element.className = "text";
    element.innerHTML = body;

    return element;
  }

  public static removeAllChild(element: HTMLElement): HTMLElement {
    element.innerHTML = "";
    return element;
  }
}

export class AnnouncementBuilderV130 {
  constructor() {}

  public static build(
    title: string,
    body: string,
    banner: File,
    category: Category,
    tag: string,
    date: Date
  ) {
    const childWindow = (
      document.getElementById("preview-html") as HTMLIFrameElement
    ).contentWindow;
    if (!childWindow) return;

    const child = childWindow.document;

    let headerElement = child.getElementById("header");
    let titleElement = child.getElementById("title");
    let bannerElement = child.getElementById("banner");
    let bodyElement = child.getElementById("body");

    if (!headerElement || !titleElement || !bannerElement || !bodyElement) {
      return;
    }

    const categoryChild = this.buildCategoryElement(category, tag);
    const dateChild = this.buildDateElement(date);
    headerElement = this.removeAllChild(headerElement);
    headerElement.appendChild(categoryChild);
    headerElement.appendChild(dateChild);

    const titleChild = this.buildTitleElement(title);
    titleElement.replaceWith(titleChild);

    if (banner) {
      const bannerChild = this.buildBannerElement(banner);
      bannerElement.replaceWith(bannerChild);
    }

    const bodyChild = this.buildBodyElement(body);
    bodyElement.replaceWith(bodyChild);

    return;
  }

  public static buildCategoryElement(
    category: string,
    tag: string
  ): HTMLParagraphElement {
    const element = document.createElement("p");
    element.className = `category ${category}`;
    element.textContent = tag;

    return element;
  }

  public static buildDateElement(date: Date): HTMLParagraphElement {
    const element = document.createElement("p");
    element.className = "date";
    element.textContent = date.toLocaleString();

    return element;
  }

  public static buildTitleElement(title: string): HTMLParagraphElement {
    const parent = document.createElement("div");
    parent.id = "title";
    parent.className = "block_title";

    const element = document.createElement("p");
    element.textContent = title;

    parent.appendChild(element);

    return parent;
  }

  public static buildBannerElement(file: File): HTMLParagraphElement {
    const element = document.createElement("img");
    element.id = "banner";
    element.className = "photo";

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;
        if (result) {
          element.src = result as string;
        }
      };

      fileReader.readAsDataURL(file);
    }

    return element;
  }

  public static buildBodyElement(body: string): HTMLParagraphElement {
    const element = document.createElement("div");
    element.id = "body";
    element.className = "text";
    element.innerHTML = body;

    return element;
  }

  public static removeAllChild(element: HTMLElement): HTMLElement {
    element.innerHTML = "";
    return element;
  }
}
