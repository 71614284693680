import { useState, useEffect } from "react";
import { useRecordContext } from "react-admin";

export const ImageSize = (props: { source: string }) => {
  const record = useRecordContext();
  const imageURL = record[props.source];
  const [size, setSize] = useState({ height: 0, width: 0 });

  useEffect(() => {
    (async () => {
      setSize(await imageSize(imageURL));
    })();
  }, [imageURL]);

  return (
    <span>
      {size.height}×{size.width}
    </span>
  );
};

const imageSize = async (src: string | ArrayBuffer | Blob) => {
  return new Promise<{ height: number; width: number }>((resolve, reject) => {
    const image = new Image();
    let source: string;

    if (typeof src === "string") {
      source = src;
    } else if (src instanceof ArrayBuffer) {
      source = URL.createObjectURL(new Blob([new Uint8Array(src)]));
    } else if (src instanceof Blob) {
      source = URL.createObjectURL(src);
    } else {
      throw new Error(`Invalid argument provided`);
    }

    console.log(source);

    image.onload = () => {
      const { height, width } = image;

      if (src instanceof Blob) URL.revokeObjectURL(source);

      resolve({ height, width });
    };

    image.onerror = (err) => reject(err);

    image.src = source;
  });
};
