import { useLayoutEffect } from "react";
import { useInput } from "react-admin";
import { AnnouncementBuilder, AnnouncementBuilderV130 } from "./announcementBuilder";

export const AnnouncementBuilderComponent = () => {
  const { field: title } = useInput({ source: "Title" });
  const { field: body } = useInput({ source: "Body" });
  const { field: category } = useInput({ source: "Category" });
  const { field: tag } = useInput({ source: "Tag" });
  const { field: openAt } = useInput({ source: "OpenAt" });

  useLayoutEffect(() => {
    (async () => {
      const date = new Date(openAt.value);
      AnnouncementBuilder.build(title.value, body.value, category.value, tag.value, date);
    })();
  }, [title, body, category, openAt]);

  return <></>;
};

export const HTMLPreview = (props: any) => {
  return <iframe id="preview-html" height="530px" width="100%" className="preview" src={props.url}></iframe>;
};

export const HTMLPreviewRawText = (props: any) => {
  return <iframe id="preview-html" height="530px" width="100%" className="preview" srcDoc={props.html}></iframe>;
};

export const AnnouncementBuilderComponentV130 = () => {
  const { field: title } = useInput({ source: "Title" });
  const { field: body } = useInput({ source: "Body" });
  const { field: banner } = useInput({ source: "Banner" });
  const { field: category } = useInput({ source: "Category" });
  const { field: tag } = useInput({ source: "Tag" });
  const { field: openAt } = useInput({ source: "OpenAt" });

  useLayoutEffect(() => {
    (async () => {
      const date = new Date(openAt.value);
      AnnouncementBuilderV130.build(title.value, body.value, banner.value.rawFile, category.value, tag.value, date);
    })();
  }, [title, body, banner, category, openAt]);

  return <></>;
};
